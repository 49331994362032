<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="审核状态:">
                        <el-select v-model="filter.auditStatus" placeholder="请选择审核状态" clearable>
                            <el-option
                                v-for="item in auditStatusArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="短信模板:">
                        <el-select v-model="filter.smsTemplate" placeholder="请选择短信模板" clearable>
                            <el-option
                                v-for="item in smsTemplateArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发送状态:">
                        <el-select v-model="filter.sendSmsStatus" placeholder="请选择发送状态" clearable>
                            <el-option
                                v-for="item in sendSmsStatusArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发送人群:">
                        <el-select v-model="filter.sendCrowd" placeholder="请选择发送人群" clearable>
                            <el-option
                                v-for="item in sendCrowdArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                            <el-pagination
                                layout="prev, pager, next"
                                :total="sendCrowdPage.DataCount"
                                @current-change="sendCrowdPageChange"
                            >
                            </el-pagination>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <!-- <el-table-column type='index' label="序号" width='60' align="center"></el-table-column> -->
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column prop="NotificationName" label="通知名称" align="center" show-overflow-tooltip min-width="100">
                <!-- <template slot-scope='scope'>
                    <router-link :to="{}" style='color:#409EFF;'>{{scope.row.NotificationName}}</router-link>
                </template> -->
            </el-table-column>
            <el-table-column prop="SmsTemplate" label="短信模板" align="center" show-overflow-tooltip min-width="100">
                <template slot-scope='scope'>
                    <el-button @click="viewsSmsTemplate(scope.row)" type="text">{{scope.row.SmsTemplate}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="SendCrowd" label="发送人群" align="center" show-overflow-tooltip min-width="100">
                <template slot-scope='scope'>
                    <el-button @click="toUnitListPage(scope.row)" type="text">{{scope.row.SendCrowd}}</el-button>
                    <!-- <router-link :to="{path:'/UnitList',query:{hasPhone: null,ID: scope.row.NoticeManageRecordID,hasSocialUnit: scope.row.SendCrowd ? 1 : 0}}" style='color:#409EFF;'>{{scope.row.SendCrowd}}</router-link> -->
                </template>
            </el-table-column>
            <el-table-column prop="GroupType" label="类型" align="center">
                <template slot-scope='scope'>
                    <span v-if="scope.row.GroupType == 2" type="text">应训人员</span>
                    <span v-else-if="scope.row.GroupType == 1" type="text">社会单位</span>
                    <span v-else type="text">其他</span>
                </template>
            </el-table-column>
            <el-table-column prop="SendTime" label="发送日期" align="center" min-width="100"></el-table-column>
            <el-table-column prop="SendPersonNumber" label="发送短信人数" align="center" width="120">
                <template slot-scope='scope'>
                    <el-button @click="toUnitListPage(scope.row)" type="text">{{scope.row.SendPersonNumber}}</el-button>
                    <!-- <router-link :to="{path:'/UnitList',query:{hasPhone: 1,ID: scope.row.NoticeManageRecordID,hasSocialUnit: scope.row.SendCrowd ? 1 : 0}}" style='color:#409EFF;'>{{scope.row.SendPersonNumber}}</router-link> -->
                </template>
            </el-table-column>
            <el-table-column prop="SuccessPersonNumber" label="发送短信成功人数" align="center" width="140">
                <template slot-scope='scope'>
                    <el-button @click="toSendNoteListPage(scope.row,1)" type="text">{{scope.row.SuccessPersonNumber}}</el-button>
                    <!-- <router-link :to="{path:'/SendNoteList',query:{isSendSuccess: 1,ID: scope.row.NoticeManageRecordID}}" style='color:#409EFF;'>{{scope.row.SuccessPersonNumber}}</router-link> -->
                </template>
            </el-table-column>
            <el-table-column prop="FailPersonNumber" label="发送短信失败人数" align="center" width="140">
                <template slot-scope='scope'>
                    <el-button @click="toSendNoteListPage(scope.row,2)" type="text">{{scope.row.FailPersonNumber}}</el-button>
                    <!-- <router-link :to="{path:'/SendNoteList',query:{isSendSuccess: 2,ID: scope.row.NoticeManageRecordID}}" style='color:#409EFF;'>{{scope.row.FailPersonNumber}}</router-link> -->
                </template>
            </el-table-column>
            <el-table-column prop="AuditStatus" label="系统审核" align="center" width="110">
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.AuditStatus == 1" type="success">审核通过</el-tag>
                    <el-tag v-else-if="scope.row.AuditStatus == 2" type="danger">审核驳回</el-tag>
                    <el-tag v-else>待审核</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 短信模板 -->
        <el-dialog
            title="短信模板"
            :visible.sync="smsTemplateDialog"
            v-model="smsTemplateDialog"
            :close-on-click-modal="false"
            width='500px'
        >
            {{smsTemplateCenter}}
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="smsTemplateDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 新建短信群发 -->
        <el-dialog
            title="新建短信群发"
            :visible.sync="applyForSendSMSDialog"
            v-model="applyForSendSMSDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-form :model="applyForSendSMSForm" :rules="applyForSendSMSRules" ref="applyForSendSMSRef" label-width="120px" style='min-width="800px"'>
                <el-form-item label="通知名称:" prop="name">
                    <el-input v-model='applyForSendSMSForm.name'></el-input>
                </el-form-item>
                <el-form-item label="模板类型:" prop="modelType">
                    <el-select v-model="applyForSendSMSForm.modelType" placeholder="请选择短信模板" clearable>
                        <el-option
                            v-for="item in modelTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板名称:" prop="SMSTemp">
                    <el-select v-model="applyForSendSMSForm.SMSTemp" placeholder="请选择短信模板" clearable>
                        <span v-if="applyForSendSMSForm.modelType == 0">
                            <el-option
                                v-for="item in smsTemplateArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </span>
                        <span v-if="applyForSendSMSForm.modelType == 1">
                            <el-option
                                v-for="item in pushTemplateArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                            <el-pagination
                                layout="prev, pager, next"
                                @current-change="changeIndex"
                                :total="pushTempPages.dataCount">
                            </el-pagination>
                        </span>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板内容:">
                    <div class="modelContent">
                        {{modelContent}}
                    </div>
                </el-form-item>
                <el-form-item label="最大限额:" prop="batchMax">
                    <el-input
                        type="number"
                        placeholder="请输入最大限额"
                        v-model="applyForSendSMSForm.batchMax"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="群发对象:" prop="SMStarget">
                    <el-radio-group v-model="applyForSendSMSForm.SMStarget">
                        <el-radio :label="0">按名单筛选</el-radio>
                        <el-radio :label="1" v-if="applyForSendSMSForm.modelType == 0">输入手机号</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" v-if="applyForSendSMSForm.SMStarget == 0" prop="nameList">
                    <el-select v-model="applyForSendSMSForm.nameList" placeholder="请选择名单" clearable>
                        <el-option
                            v-for="item in sendCrowdArr"
                            :key="item.Id"
                            :label="item.Name"
                            :value="item.Id"
                        >
                        </el-option>
                        <el-pagination
                            layout="prev, pager, next"
                            :total="sendCrowdPage.DataCount"
                            @current-change="sendCrowdPageChange"
                        >
                        </el-pagination>
                    </el-select>
                </el-form-item>
                <el-form-item label="" v-if="applyForSendSMSForm.SMStarget == 1" prop="SMSContent">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请输入手机号"
                        v-model="applyForSendSMSForm.SMSContent"
                        clearable
                        maxlength="300"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label='发送时间:' prop="sendSMSType">
                    <el-radio-group v-model="applyForSendSMSForm.sendSMSType">
                        <el-radio :label="0">立即发送</el-radio>
                    </el-radio-group>
                </el-form-item>         
                <el-form-item label=''>
                    <el-radio-group v-model="applyForSendSMSForm.sendSMSType">
                        <el-radio :label="1">定时发送</el-radio>
                    </el-radio-group>
                    <span style="margin-left:20px;">
                        <el-date-picker
                            v-model="applyForSendSMSForm.sendSMSTime"
                            :disabled="applyForSendSMSForm.sendSMSType == 0"
                            type="datetime"
                            editable
                            clearable
                            :picker-options="pickerOptions"
                            placeholder="选择日期时间"
                        >
                        </el-date-picker>
                    </span>
                </el-form-item>         
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="applyForSendSMSubmit" :loading="applyForSendSMLoading">申请发送</el-button>
                <el-button @click.native="applyForSendSMSDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 手机号 -->
        <el-dialog
            title="手机号"
            :visible.sync="PhoneDialog"
            v-model="PhoneDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="PhoneData"
                border
                style="width: 100%"
                v-loading="phoneLoading"
                ref="multipleTable"
                :highlight-current-row='true'
            >
                <el-table-column prop="CellPhoneNumber" label="手机号" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
            <!--底部工具条-->
            <el-col :span="24" class="pageBar">
                <el-pagination
                    @size-change="handlePhoneSizeChange"
                    @current-change="handlePhoneCurrentChange"
                    :current-page="PhonePages.pageIndex"
                    :page-sizes="PhonePages.pageArr"
                    :page-size="PhonePages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="PhonePages.dataCount"
                >
                </el-pagination>
            </el-col>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="applyForSendSMSubmit" :loading="applyForSendSMLoading">申请发送</el-button> -->
                <el-button @click.native="PhoneDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import router from '@/router'
import { getButtonList } from "../../promissionRouter";
import { listForNotificationManagement,getSocialGroupName,selectAllSmsTemplateName,approved,reviewFailed,applyForSendSMS,getStudentNoticeTemplateListPage,listForSendSMS,resendSMS,failedSendSMSResend } from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data() {
        return {
            buttonList: [],
            needData: '',
            filter: {
                auditStatus: '',
                smsTemplate: '',
                sendSmsStatus: '',
                sendCrowd: '',
            },
            auditStatusArr: [
                {Name:'待审核',value:0},
                {Name:'审核通过',value:1},
                {Name:'审核驳回',value:2},
            ],
            smsTemplateArr: [],
            pushTemplateArr: [],
            sendSmsStatusArr: [
                {Name:'待发送',value:0},
                {Name:'发送中',value:1},
                {Name:'已发送',value:2},
            ],
            modelTypeArr: [
                {label:'短信模板',value:0},
                {label:'推送模板',value:1},
            ],
            sendCrowdPage: {
                DataCount: 10,
            },
            sendCrowdArr: [],
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            pushTempPages: {
                pageIndex: 1,
                pageSize: 10,
                dataCount: 0,
            },
            // 短信模板
            smsTemplateDialog: false,
            smsTemplateCenter: '',
            // 新建短信群发
            applyForSendSMSDialog: false,
            applyForSendSMLoading: false,
            applyForSendSMSForm: {
                name: '',
                SMSTemp: '',
                SMStarget: 0,
                SMSContent: '',
                nameList: '',
                sendSMSType: 0,
                sendSMSTime: '',
                batchMax: '',
                modelType: '',
            },
            modelContent: '',
            applyForSendSMSRules:{
                name:[{required:true,message:'请选择通知名称',trigger:'blur'}],
                modelType:[{required:true,message:'请选择模板类型',trigger:'blur'}],
                SMSTemp:[{required:true,message:'请选择短信模板',trigger:'blur'}],
                SMStarget:[{required:true,message:'请选择群发对象',trigger:'blur'}],
                nameList:[{required:true,message:'请选择名单',trigger:'blur'}],
                SMSContent:[{required:true,message:'请输入手机号',trigger:'blur'}],
                sendSMSType:[{required:true,message:'请选择发送时间',trigger:'blur'}],
                batchMax:[{required:true,message:'请输入最大限额',trigger:'blur'}],
            },
            pickerOptions:{
                disabledDate:(time)=> {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            // 手机号
            PhoneDialog: false,
            phoneLoading: false,
            PhoneData: [],
            //关于分页的obj
            PhonePages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getDataList(){
            var params = {
                auditStatus: this.filter.auditStatus,
                noticeTemplateID: this.filter.smsTemplate,
                socialGroupID: this.filter.sendCrowd,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true
            listForNotificationManagement(params).then(res => {
                var result = res.data
                this.listLoading = false
                if(result.Success) {
                    this.pages.dataCount = result.Response.DataCount
                    this.tableData = result.Response.Data
                }
            })
        },
        // 查询
        listForNotificationManagement() {
            this.getDataList();
        },
        // 新建短信群发
        applyForSendSMS() {
            this.applyForSendSMSDialog = true
            this.applyForSendSMLoading = false
            this.applyForSendSMSForm = {
                name: '',
                SMSTemp: '',
                SMStarget: 0,
                SMSContent: '',
                nameList: '',
                sendSMSType: 0,
                sendSMSTime: '',
                batchMax: '',
                modelType: '',
            }
        },
        // 新建短信群发提交
        applyForSendSMSubmit() {
            this.$refs['applyForSendSMSRef'].validate(valid => {
                if(valid){
                    if(this.applyForSendSMSForm.sendSMSType == 1 && !this.applyForSendSMSForm.sendSMSTime) {
                        this.$message.warning("请选择日期时间")
                        return
                    } else {
                        var selTime = Math.round(new Date(this.applyForSendSMSForm.sendSMSTime).getTime() / 1000)
                        if(selTime < Math.round(new Date().getTime() / 1000)){
                            this.$message.warning("请选择当前之后时间")
                            return
                        }
                    }
                    var params = {
                        Name: this.applyForSendSMSForm.name,
                        NoticeTemplateID: this.applyForSendSMSForm.SMSTemp,
                        SendObjectStatus: this.applyForSendSMSForm.SMStarget,
                        SendTimeType: this.applyForSendSMSForm.sendSMSType,
                        ModelType: this.applyForSendSMSForm.modelType,
                        BatchMax: parseInt(this.applyForSendSMSForm.batchMax),
                    }
                    if(this.applyForSendSMSForm.SMStarget == 0) {
                        params.SocialGroupID = this.applyForSendSMSForm.nameList
                    } else {
                        params.Phone = this.applyForSendSMSForm.SMSContent
                    }
                    if(this.applyForSendSMSForm.sendSMSType == 1) {
                        params.NoticeTime = this.timeChange(this.applyForSendSMSForm.sendSMSTime)
                    }
                    applyForSendSMS(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.applyForSendSMSDialog = false
                            this.applyForSendSMLoading = false
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                }
            })
        },
        // 审核通过
        approved() {
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData.length > 0) {
                this.$confirm(`确定审核通过选中的一项？`,"提示",{
                }).then(() => {
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.NoticeManageRecordID)
                    });
                    var params = {
                        Ids: ids
                    }
                    approved(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请至少选择一行！")
            }
        },
        // 审核驳回
        reviewFailed() {
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData.length > 0) {
                this.$confirm(`确定审核驳回选中的一项？`,"提示",{
                }).then(() => {
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.NoticeManageRecordID)
                    });
                    var params = {
                        Ids: ids
                    }
                    reviewFailed(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请至少选择一行！")
            }
        },
        // 重新发送短信
        reSendSMS() {
            var selectionData = this.$refs.multipleTable.selection
            if(this.needData) {
                this.$confirm(`确定重新发送短信？`,"提示",{
                }).then(() => {
                    // var ids = []
                    // selectionData.forEach(item => {
                    //     ids.push(item.NoticeManageRecordID)
                    // });
                    // var params = {
                    //     noticeManageRecordID: ids[0]
                    // }
                    var params = {
                        noticeManageRecordID: this.needData.NoticeManageRecordID
                    }
                    resendSMS(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请选择重新发送短信一行！")
            }
        },
        // 失败短信重发
        FailedSendSMS() {
            var selectionData = this.$refs.multipleTable.selection
            if(this.needData) {
                this.$confirm(`确定失败短信重发？`,"提示",{
                }).then(() => {
                    // var ids = []
                    // selectionData.forEach(item => {
                    //     ids.push(item.NoticeManageRecordID)
                    // });
                    // var params = {
                    //     noticeManageRecordID: ids[0]
                    // }
                    var params = {
                        noticeManageRecordID: this.needData.NoticeManageRecordID
                    }
                    failedSendSMSResend(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请选择失败短信重发一行！")
            }
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 获取短信模板
        getSmsTimplateFun() {
            selectAllSmsTemplateName().then(res => {
                var result = res.data
                if(result.Success) {
                    this.smsTemplateArr = result.Response
                }
            })
        },
        // 获取推送模板
        getpushTimplateFun() {
            var params = {
                noticeType: 5,
                pageIndex: this.pushTempPages.pageIndex,
                pageSize: this.pushTempPages.pageSize,
            }
            getStudentNoticeTemplateListPage(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.pushTempPages.dataCount = result.Response.DataCount
                    this.pushTemplateArr = result.Response.Data
                }
            })
        },
        changeIndex(val) {
            this.pushTempPages.pageIndex = val
            this.getpushTimplateFun();
        },
        // 查询人群名单
        getSocialGroupListPageFun() {
            getSocialGroupName().then(res => {
                var result = res.data
                if(result.Success) {
                    this.sendCrowdArr = result.Response.Data
                    this.sendCrowdPage.DataCount = result.Response.DataCount
                }
            })
        },
        // 查看短信模板
        viewsSmsTemplate(val) {
            this.smsTemplateCenter = val.Content
            this.smsTemplateDialog = true
        },
        // 点击 发送人群、发送短信人数 页面跳转
        toUnitListPage(val) {
            if(val.SendObjectType == 1) {
                this.PhoneDialog = true
                this.getPhoneDataList(val)
            } else {
                // 1 单位
                if(val.GroupType == 1) {
                    router.push({
                        path: "/UnitList",
                        query: {hasPhone: null,ID: val.NoticeManageRecordID,hasSocialUnit: val.SendCrowd ? 1 : 0}
                    });
                // 2 职员
                } else if(val.GroupType == 2) {
                    router.push({
                        path: "/NoticCrowdList",
                        query: {hasPhone: null,ID: val.NoticeManageRecordID,hasSocialUnit: val.SendCrowd ? 1 : 0}
                    });
                }
            }
        },
        // 点击 发送短信成功/失败人数 页面跳转
        toSendNoteListPage(val,isSendSuccess) {
            if(val.SendObjectType == 1) {
                this.PhoneDialog = true
                this.getPhoneDataList(val,isSendSuccess)
            } else {
                // 1 单位
                if(val.GroupType == 1) {
                    router.push({
                        path: "/SendNoteList",
                        query: {isSendSuccess: isSendSuccess,ID: val.NoticeManageRecordID}
                    });
                // 2 职员
                } else if(val.GroupType == 2) {
                    router.push({
                        path: "/NoticCrowdList",
                        query: {isSendSuccess: isSendSuccess,hasPhone: null,ID: val.NoticeManageRecordID,hasSocialUnit: val.SendCrowd ? 1 : 0}
                    });
                }
            }
        },
        // 获取手机号名单
        getPhoneDataList(val,statusVal) {
            var params = {
                noticeManageRecordID: val.NoticeManageRecordID,
                sendStatus: statusVal,
                PageIndex: this.PhonePages.pageIndex,
                PageSize: this.PhonePages.pageSize,
            }
            this.phoneLoading = true
            listForSendSMS(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.PhoneData = result.Response.Data
                    this.PhonePages.dataCount = result.Response.DataCount
                }
                this.phoneLoading = false
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 分页
        handlePhoneCurrentChange(val) {
            this.PhonePages.pageIndex = val;
            this.getPhoneDataList();
        },
        handlePhoneSizeChange(val){
            this.PhonePages.pageSize = val;
            this.getPhoneDataList();
        },
        sendCrowdPageChange(val) {
            this.sendCrowdPage.DataCount = val
            this.getSocialGroupListPageFun();
        },
        timeChange(value){
            return this.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(value))
        },
        dateFormat(format,date){
            let ret = ''
            date = date
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 筛选项input框清空
        handleClear(){
            
        },
    },
    watch: {
        'applyForSendSMSForm.modelType': function(val) {
            this.applyForSendSMSForm.SMSTemp = ""
            this.modelContent = ""
        },
        'applyForSendSMSForm.SMSTemp': function(val) {
            if(this.applyForSendSMSForm.modelType == 0) {
                this.smsTemplateArr.forEach(item => {
                    if(item.Id == val) {
                        this.modelContent = item.Content
                    }
                })
            } else {
                this.pushTemplateArr.forEach(item => {
                    if(item.ID == val) {
                        this.modelContent = item.Content
                    }
                })
            }
        }
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
        this.getSmsTimplateFun();
        this.getpushTimplateFun();
        this.getSocialGroupListPageFun();
    }
}
</script>

<style lang="stylus" scoped>
    .modelContent {
        width: 80%;
        background: #eee;
        padding: 0 15px;
    }
</style>